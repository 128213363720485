<template>
  <div class="app-main">
    <!-- <keep-alive> -->
      <router-view :key="$route.fullPath" />
    <!-- </keep-alive> -->
  </div>
</template>

<script>
export default {
  name: 'AppMain',
  components: {},
  data() {
    return {};
  },
  mixins: [],
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.app-main {
  position: relative;
  padding: 15px;
  background-color: #f8f8f8;
  flex: 1;
  overflow: hidden;
}
</style>
