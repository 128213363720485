/* eslint-disable no-param-reassign */
import Vue from 'vue';
import axios from 'axios';
import { getToken } from '@/utils/common';
import baseConfig from '../../config';
import router from '@/router';
import { removeToken } from '@/utils/common';
import cache from '@/utils/cache';

const errorFn = (err, response) => {
  if (err.toString().indexOf('timeout') !== -1) {
    Vue.prototype.$message.error('请求超时，请稍后再试');
  }
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        const errUrlList = ['manager/order/exportStat', 'manager/order/export'];
        if (errUrlList.includes(err.response?.config?.url)) {
          err.message = '暂无符合条件的订单';
        } else {
          err.message = err.response?.data?.message || '请求错误';
        }
        Vue.prototype.$message.error(err.message);
        break;

      case 401:
        err.message = '未授权，请登录';
        break;

      case 403:
        removeToken();
        cache.remove('carez-userInfo');
        router.push('/login');
        break;

      case 404:
        err.message = `请求地址出错: ${err.response.config.url}`;
        break;

      case 408:
        err.message = '请求超时';
        break;

      case 500:
        err.message = err.response.data.message || '服务器开个小差，请稍后再试';
        break;

      case 501:
        err.message = '服务器开个小差，请稍后再试';
        break;

      case 502:
        err.message = '服务器开个小差，请稍后再试';
        break;

      case 503:
        err.message = '服务器开个小差，请稍后再试';
        break;

      case 504:
        err.message = '服务器开个小差，请稍后再试';
        break;

      case 505:
        err.message = 'HTTP版本不受支持';
        break;

      default:
        err.message = '请求失败，请稍后再试';
    }
    // Vue.prototype.$message.error(err.message);
  } else {
    Vue.prototype.$message.error('请求失败，请稍后再试');
  }
  return Promise.reject(err);
};

axios.defaults.baseURL = baseConfig.baseURL;
axios.defaults.timeout = 30000;
// 此处开始封装不同后台下的axios实例
const request = axios.create({
  // baseURL: "http://60.205.245.202:18082/",
  // timeout: 30000,
  withCredentials: true,
});

request.interceptors.request.use(
  (config) => {
    if (config.method === 'get') {
      if (config.url.indexOf('?') < 0) {
        config.url += `?r=${new Date().getTime()}`;
      } else {
        config.url += `&r=${new Date().getTime()}`;
      }
    }
    try {
      config.headers.Authorization = getToken() || '';
      config.headers.app = 'carez';
    } catch (errMsg) {
      console.log(errMsg);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use((response) => {
  const res = response.data;
  if (response.config.responseType === 'blob') {
    return response;
  }
  // 返回数据的code 不为200即为数据出错
  if (res.code === 888) {
    Vue.prototype.$message.error('token已失效请重新登录');
    removeToken();
    cache.remove('carez-userInfo');
    router.push('/login');
    // return res;
  } else {
    if (res.code === 0) {
      return res;
    }
    if (!response.config.customTips) Vue.prototype.$message.error(res.msg);

    return res;
  }
}, errorFn);

const carezHttp = request;
export default carezHttp;
