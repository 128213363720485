import Vue from 'vue';
import router from '@/router';
import { removeToken } from '@/utils/common';
import cache from '@/utils/cache';
import carezHttp from '@/utils/request';
import store from '@/store';

export function forgetPass() {
  Vue.prototype.$alert('请您联系管理员进行密码修改', '', {
    confirmButtonText: '知道了',
    type: 'info',
    callback: () => {},
  });
}

export function logout() {
  Vue.prototype
    .$confirm('您确定要退出登录吗', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'info',
    })
    .then(() => {
      // TODO 清楚本地缓存，token
      removeToken();
      cache.remove('carez-userInfo');
      router.push('/login');
      store.dispatch('user/setUsers', {})
    })
    .catch(() => {
      // 取消退出
    });
}

/**
 * 获取菜单栏品牌列表
 */
export function getBrandMenuList() {
  return carezHttp({
    url: `manager/brand/list`,
    method: 'GET',
  });
}

/**
 * 获取全部业务员
 * @param {*} params 
 */
export function getSalesman() {
  return carezHttp({
    url: `manager/user/listSalesman`,
    method: 'GET',
  });
};
