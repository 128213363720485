import Layout from '@/layout';

export default {
  path: '/distributor',
  name: 'distributor',
  component: Layout,
  redirect: '',
  meta: {
    title: '分销商管理',
    icon: 'el-icon-menu',
    sortIndex: '4',
  },
  children: [
    {
      path: 'list',
      name: 'distributorList',
      component: () => import('@/pages/distributor/list'),
      meta: {
        fatherTitle: '分销商管理',
        title: '分销商列表',
        sortIndex: '4-1',
      },
    },
    {
      path: 'level',
      name: 'distributorLevel',
      component: () => import('@/pages/distributor/level'),
      meta: {
        fatherTitle: '分销商管理',
        title: '充值等级管理',
        sortIndex: '4-2',
      },
    },
    {
      path: 'detail',
      name: 'distributorDetail',
      component: () => import('@/pages/distributor/detail'),
      meta: {
        fatherTitle: '分销商管理',
        title: '分销商详情',
        sortIndex: '4-3',
      },
    }
  ],
};
