import Layout from '@/layout';

export default {
  path: '/couponcard',
  name: 'couponcard',
  component: Layout,
  redirect: '',
  meta: {
    title: '优惠卡管理',
    icon: 'el-icon-menu',
    sortIndex: '7',
  },
  children: [
    {
      path: 'list',
      name: 'couponcardList',
      component: () => import('@/pages/couponCard/list'),
      meta: {
        fatherTitle: '优惠卡管理',
        title: '优惠卡列表',
        sortIndex: '7-1',
      },
    },
    {
      path: 'add',
      name: 'couponcardAdd',
      component: () => import('@/pages/couponCard/add'),
      meta: {
        grandFatherTitle: '优惠卡管理',
        fatherTitle: '优惠卡列表',
        title: '新增',
        sortIndex: '7-1',
      },
    },
    {
      path: 'edit',
      name: 'couponcardEdit',
      component: () => import('@/pages/couponCard/add'),
      meta: {
        grandFatherTitle: '优惠卡管理',
        fatherTitle: '优惠卡列表',
        title: '修改',
        sortIndex: '7-1',
      },
    },
    {
      path: 'detail',
      name: 'couponcardDetail',
      component: () => import('@/pages/couponCard/detail'),
      meta: {
        grandFatherTitle: '优惠卡管理',
        fatherTitle: '优惠卡列表',
        title: '优惠卡详情',
        sortIndex: '7-1',
      },
    },
    {
      path: 'activations',
      name: 'activeList',
      component: () => import('@/pages/couponCard/activeList'),
      meta: {
        fatherTitle: '优惠卡管理',
        title: '兑换卡片激活明细',
        sortIndex: '7-2',
      },
    },
  ],
};
