/**
 * 优惠券 页面 api 请求
 */
import carezHttp from '@/utils/request';

/**
 * 获取优惠卡列表
 * @param {*} id
 * @returns
 */
const getCouponCardList = (params) => {
  return carezHttp({
    url: `manager/card/listCard`,
    method: 'post',
    data: params,
  });
};

/**
 * 获取已激活优惠卡列表
 * @param {*} id
 * @returns
 */
const getActiveList = (params) => {
  return carezHttp({
    url: `manager/card/listActiveRedeemCode`,
    method: 'post',
    data: params,
  });
};

/**
 * 获取优惠卡详情
 * @returns
 */
const getDetailList = (params) => {
  return carezHttp({
    url: `manager/card/listCardDetail`,
    method: 'post',
    data: params,
  });
};

/**
 * 新增卡片
 * @param {*} parmas
 * @returns
 */
const addCouponCard = (data) => {
  return carezHttp({
    url: `manager/card/addCard`,
    method: 'post',
    data,
  });
};

/**
 * 获取卡片详情
 * @param {*} parmas
 * @returns
 */
const getCouponCard = (cardId) => {
  return carezHttp({
    url: `manager/card/getCard/${cardId}`,
    method: 'get',
  });
};

/**
 * 修改卡片
 * @param {*} parmas
 * @returns
 */
const editCouponCard = (parmas) => {
  return carezHttp({
    url: `manager/card/updateCard`,
    method: 'post',
    data: parmas,
  });
};

/**
 * 获取C端商品信息
 * @param {*} parmas
 * @returns
 */
const getCustomerGoods = (goodsId) => {
  return carezHttp({
    url: `manager/card/getCustomerGoods/${goodsId}`,
    method: 'get',
  });
};

/**
 * 作废
 * @param {*} parmas
 * @returns
 */
const cancelRedeemCode = (redeemCode) => {
  return carezHttp({
    url: `manager/card/cancelRedeemCode/${redeemCode}`,
    method: 'get',
  });
};

/**
 * 整套导出
 * @param {*} parmas
 * @returns
 */
const exportCard = (cardId) => {
  return carezHttp({
    url: `manager/card/export/${cardId}`,
    method: 'get',
    timeout: 60000,
    responseType: 'blob',
  });
};

export {
  getCouponCardList,
  getActiveList,
  getDetailList,
  addCouponCard,
  getCouponCard,
  editCouponCard,
  getCustomerGoods,
  cancelRedeemCode,
  exportCard,
};
