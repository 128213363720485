/**
 * 系统设置 页面 api 请求
 */
import carezHttp from '@/utils/request';
import { AESUtil } from '@/utils/common';

/**
 * 获取后台用户列表
 * @param {*} params
 * @returns
 */
const getUserList = (params) => {
  const httpParams = {
    name: params.name || '',
    pageNo: params.pageNo || 1,
    pageSize: params.pageSize || 20,
  };
  return carezHttp({
    url: `manager/user/list`,
    method: 'POST',
    data: httpParams,
  });
};

/**
 * 获取后台用户详情
 * @param {*} id
 * @returns
 */
const getUserInfo = (id) => {
  return carezHttp({
    url: `manager/user/info/${id}`,
    method: 'get',
  });
};

/**
 * 新增或编辑后台用户信息
 */
const saveUserChange = (params, isUpdate) => {
  const httpParams = { ...params };
  if (isUpdate) {
    // 如果是编辑用户信息时，不能修改用户密码，所以不传password此参数给后端
    delete httpParams.password;
  } else {
    httpParams.password = AESUtil.aesEncrypt(httpParams.password);
  }

  httpParams.email = httpParams.name;
  return carezHttp({
    url: `/manager/user/save`,
    method: 'post',
    data: httpParams,
  });
};

/**
 * 删除指定用户
 * @param {*} id
 * @returns
 */
const deleteUser = (id) => {
  return carezHttp({
    url: `manager/user/delete/${id}`,
    method: 'post',
    data: {},
  });
};

/**
 * 修改后台用户密码
 * @param {*} params
 * @returns
 */
const changePassword = (params) => {
  const httpParams = { ...params };
  httpParams.password = AESUtil.aesEncrypt(httpParams.password);
  return carezHttp({
    url: `/manager/user/reset`,
    method: 'post',
    data: httpParams,
  });
};

/**
 * 获取快递方式列表
 * @param {*} params
 * @returns
 */
const getSendList = (params) => {
  const httpParams = {
    pageNo: params.pageNo || 1,
    pageSize: params.pageSize || 20,
  };
  return carezHttp({
    url: `manager/delivery/list`,
    method: 'POST',
    data: httpParams,
  });
};

/**
 * 删除快递方式
 */
const deleteDelivery = (id) => {
  return carezHttp({
    url: `manager/delivery/delete/${id}`,
    method: 'post',
    data: {},
  });
};

/**
 * 新增或修改快递方式
 * @param {*} params
 * @returns
 */
const changeSendtype = (params) => {
  return carezHttp({
    url: `manager/delivery/save`,
    method: 'post',
    data: params,
  });
};

/**
 * 获取系统参数配置
 */
const getSystemSetting = (params) => {
  const httpParams = {
    pageNo: params.pageNo || 1,
    pageSize: params.pageSize || 20,
  };
  return carezHttp({
    url: `manager/setting/list`,
    method: 'POST',
    data: httpParams,
  });
};

/**
 * 更新系统参数配置
 * @param {*} params
 */
const updateSetting = (params) => {
  const httpParams = {
    app: params.app,
    id: params.id,
    para: params.para,
    notice: params.notice,
  };
  return carezHttp({
    url: `manager/setting/save`,
    method: 'POST',
    data: httpParams,
  });
};

/**
 * 获取banner列表
 */
const getBannerList = (params) => {
  const httpParams = {
    pageNo: params.pageNo || 1,
    pageSize: params.pageSize || 20,
  };
  return carezHttp({
    url: `manager/banner/list`,
    method: 'POST',
    data: httpParams,
  });
};

/**
 * 删除banner
 * @param {*} id
 * @returns
 */
const deleteBanner = (id) => {
  return carezHttp({
    url: `manager/banner/delete/${id}`,
    method: 'post',
    data: {},
  });
};

/**
 * 新增或编辑banner
 * @param {*} params
 * @returns
 */
const addOrUpdateBanner = (params) => {
  return carezHttp({
    url: `manager/banner/save`,
    method: 'POST',
    data: params,
  });
};

/**
 * 获取标签列表
 * @returns
 */
const getLabelList = (params) => {
  const {
    name = '',
    pageNo = 1,
    pageSize = 20,
    parentId = 0,
    type = 0,
  } = params;
  const httpParams = {
    name,
    pageNo,
    pageSize,
    parentId,
  };
  if (type) {
    httpParams.type = type;
  }
  return carezHttp({
    url: `/manager/category/list`,
    method: 'POST',
    data: httpParams,
  });
};

/**
 * 获取父标签下子标签合集
 * @returns 
 */
const getSubCategoryList = (categoryId) => {
  return carezHttp({
    url: `manager/category/list/${categoryId}`,
    method: 'GET',
  });
};

/**
 * 删除category
 * @param {*} id 
 * @returns 
 */
const deleteCategory = (id) => {
  return carezHttp({
    url: `manager/category/delete/${id}`,
    method: 'post',
    data: {},
  });
};

const getCategoryInfo = (categoryId) => {
  return carezHttp({
    url: `manager/category/info/${categoryId}`,
    method: 'GET',
  });
}

/**
 * 新增或编辑标签
 */
const saveAndUpdateCategory = (params) => {
  return carezHttp({
    url: `manager/category/save`,
    method: 'post',
    data: params,
  });
};

/**
 * 分类商品列表
 * @param {*} categoryId 
 * @param {*} params 
 * @returns 
 */
const getGoodsListByCategoryId = (categoryId, params) => {
  return carezHttp({
    url: `manager/goods/list/${categoryId}`,
    method: 'post',
    data: params,
  });
};

/**
 * 给标签绑定商品
 * @param {*} categoryId 
 * @param {*} goods 
 * @returns 
 */
const bindGoodsForCategory = (categoryId, goods) => {
  return carezHttp({
    url: `manager/category/goods/bind`,
    method: 'post',
    data: {
      categoryId,
      goods
    },
  });
};

/**
 * 给标签解绑商品
 */
const unbindGoodsForCategory = (categoryId, goods) => {
  return carezHttp({
    url: `manager/category/goods/unbind`,
    method: 'post',
    data: {
      categoryId,
      goods
    },
  });
}

/**
 * 新增品牌
 * @param {*} params 
 * @returns 
 */
const addBrand = (params) => {
  return carezHttp({
    url: `manager/brand/add`,
    method: 'post',
    data: params,
  });
};

/**
 * 修改品牌
 * @param {*} params 
 * @returns 
 */
const updateBrand = (params) => {
  return carezHttp({
    url: `manager/brand/update`,
    method: 'post',
    data: params,
  });
};

/**
 * 删除品牌
 */
const deleteBrand = (brandId) => {
  return carezHttp({
    url: `manager/brand/delete/${brandId}`,
    method: 'post',
    data: {},
  });
};

/**
 * 获取管家婆 erp 状态
 */
const getErpStatus = () => {
  return carezHttp({
    url: `manager/getTokenStatus`,
    method: 'GET',
  });
};

/**
 * 同步所有商品库存
 */
const syncAllGoodsStock = () => {
  return carezHttp({
    url: `manager/syncAllGoodsStock`,
    method: 'GET',
  });
};


export {
  getUserList,
  getUserInfo,
  saveUserChange,
  deleteUser,
  changePassword,
  getSendList,
  deleteDelivery,
  changeSendtype,
  getSystemSetting,
  updateSetting,
  getBannerList,
  deleteBanner,
  addOrUpdateBanner,
  getLabelList,
  getSubCategoryList,
  deleteCategory,
  getCategoryInfo,
  saveAndUpdateCategory,
  getGoodsListByCategoryId,
  bindGoodsForCategory,
  unbindGoodsForCategory,
  addBrand,
  updateBrand,
  deleteBrand,
  getErpStatus,
  syncAllGoodsStock,
};
