const cache = {
  /*
   * 本地缓存
   */
  get(key) {
    let val;
    val = localStorage.getItem(key) || sessionStorage.getItem(key) || '';
    if (!val) {
      return undefined;
    }
    val = JSON.parse(val);

    // val format check
    if (val != null) {
      return val.data;
    }

    /*
      how to return illegal data for im？
      */
    return undefined;
  },
  set(key, val, isTemp) {
    let Astore = localStorage;
    if (isTemp) {
      Astore = sessionStorage;
    }
    const type = typeof val;

    Astore[key] = JSON.stringify({
      data: val,
      type,
    });
  },
  remove(key) {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  },
};
export default cache;
