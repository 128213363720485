<template>
  <div class="">
    <el-empty description="运营平台"></el-empty>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  mixins: [],
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
