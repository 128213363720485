import Layout from '@/layout';

export default {
  path: '/finance',
  name: 'finance',
  component: Layout,
  redirect: '',
  meta: {
    title: '财务报表',
    icon: 'el-icon-s-order',
    sortIndex: '8',
  },
  children: [
    {
      path: 'export',
      name: 'financeExport',
      component: () => import('@/pages/finance/export'),
      meta: {
        fatherTitle: '财务报表',
        title: '财务报表',
        sortIndex: '8-1',
      },
    },
    {
      path: 'withdrawal',
      name: 'financeWithdrawal',
      component: () => import('@/pages/finance/withdrawal'),
      meta: {
        fatherTitle: '财务报表',
        title: '提现申请',
        sortIndex: '8-2',
      },
    },
  ],
};
