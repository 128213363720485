/**
 * 销售数据统计 页面 api 请求
 */
import carezHttp from '@/utils/request';

/**
 * 导出统计数据
 * @returns
 */
const exportStat = (params = {}) => {
  let url = 'manager/order/exportStat';
  return carezHttp({
    url: url,
    method: 'POST',
    data: params,
    responseType: 'blob'
  });
};

/**
 * 获取提现申请列表
 * @returns
 */
const getWithdrawalList = (params = {}) => {
  let url = 'manager/withdraw/listWithdrawRecords';
  return carezHttp({
    url: url,
    method: 'POST',
    data: params
  });
};

/**
 * 修改提现状态
 * @returns
 */
const updateWithdrawStatus = (params = {}) => {
  let url = 'manager/withdraw/updateWithdrawStatus';
  return carezHttp({
    url: url,
    method: 'POST',
    data: params
  });
};

/**
 * 导出提现列表
 * @returns
 */
const downloadWithdrawList = (params = {}) => {
  let url = 'manager/withdraw/downloadWithdrawList';
  return carezHttp({
    url: url,
    method: 'POST',
    responseType: 'blob',
    data: params
  });
};

export {
  exportStat,
  getWithdrawalList,
  updateWithdrawStatus,
  downloadWithdrawList
};
