<template>
  <div class="app-login">
    <div class="login-left">左侧图</div>
    <div class="login-right">
      <img class="login-bg1" src="../../assets/image/logo.png" alt="login" />
      <div class="login-container">
        <div class="form-head">
          <h3 class="sub-title">欢迎登录</h3>
          <div class="sub-title-1">凯尔资分销商运营平台</div>
        </div>
        <div class="login-form">
          <el-form ref="ruleForm" :rules="rules" :model="ruleForm" status-icon>
            <el-form-item prop="name">
              <el-input
                placeholder="请输入用户名"
                v-model="ruleForm.name"
                autofocus
              >
                <i slot="prefix" class="el-input__icon el-icon-user"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                placeholder="请输入密码"
                v-model="ruleForm.password"
                show-password
                @keyup.enter.native="onSubmit"
              >
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <!-- <div class="login-row">
              <el-radio v-model="radio" :label="1">凯尔资</el-radio>
              <el-radio v-model="radio" :label="2">派膳师</el-radio>
            </div> -->
            <div class="forget-password">
              <span @click="forgetPass()">忘记密码？</span>
            </div>
            <el-form-item>
              <el-button type="primary" @click="onSubmit" :loading="loading"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { forgetPass } from '@/apis/common';
import { login } from './apis';
import cache from '@/utils/cache';
import { setToken } from '@/utils/common';

export default {
  name: 'Login',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
        // var passwordreg = /(?=.*\d)(?=.*[a-zA-Z]).{6,30}/;
        // if (!passwordreg.test(value)) {
        //   callback(new Error('密码至少由数字、字母组合,请输入6-30位'));
        // }else{
        //   callback()
        // }
      }
    };
    return {
      ruleForm: {
        name: '',
        password: '',
      },
      rules: {
        name: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
      },
      // radio: 1,
      loading: false,
    };
  },
  components: {},
  mixins: [],
  computed: {},
  methods: {
    onSubmit() {
      const context = this;
      context.$refs.ruleForm.validate((valid) => {
        // 验证通过
        if (valid) {
          const params = {
            userName: context.ruleForm.name,
            password: context.ruleForm.password,
          };
          context.loading = true;
          login(params)
            .then((res) => {
              context.loading = false;
              if (res.code === 0) {
                const userInfo = res.data || {};
                cache.set('carez-userInfo', userInfo);
                this.$store.dispatch('user/setUsers', userInfo);
                setToken(userInfo?.token);
                context.$message({
                  message: '登录成功',
                  type: 'success',
                });
                context.$router.push('/home');
                return;
              }
              // 请求成功，但是登录失败
              context.$message({
                message: res?.data?.message || '登录失败',
                type: 'error',
              });
            })
            .catch(() => {
              context.loading = false;
            });
        }
      });
    },
    forgetPass() {
      forgetPass();
    },
  },
};
</script>

<style lang="scss" scoped>
.app-login {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f9f9f9;
  .login-left {
    position: relative;
    height: 100%;
    width: 43.6%;
    flex-shrink: 0;
    font-size: 0;
    background-image: url(../../assets/image/login_bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .login-right {
    position: relative;
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .login-bg1 {
      position: absolute;
      right: 50px;
      top: 50px;
      width: 110px;
      height: auto;
      display: block;
    }
    .login-container {
      position: relative;
      .form-head {
        position: relative;
        text-align: center;
        font-weight: 700;
        margin-bottom: 40px;
        .sub-title {
          color: #5f6976;
          font-size: 30px;
          margin-bottom: 10px;
        }
        .sub-title-1 {
          color: #0b0d0f;
          font-family: PingFang SC;
          font-size: 40px;
        }
      }
      .login-form {
        position: relative;
        border-radius: 10px;
        height: 425px;
        padding: 72px 45px;
        width: 546px;
        box-sizing: border-box;
        background: #fff;
        /deep/ .el-input .el-input__inner {
          border: none !important;
          height: 60px;
          background-color: #f9f9f9;
          border-radius: 8px;
          font-size: 18px;
          color: #000;
          padding-left: 50px;
        }
        /deep/ .el-input .el-input__icon {
          font-size: 20px;
          width: 40px;
        }
        /deep/ .el-button {
          width: 100%;
          height: 50px;
          font-size: 18px;
          border-radius: 6px;
        }
        .login-row {
          position: relative;
          margin-top: 5px;
          margin-bottom: 20px;
        }
        .forget-password {
          position: relative;
          margin-bottom: 20px;
          height: 28px;
          line-height: 28px;
          text-align: right;
          span {
            color: #5767fa;
            font-size: 16px;
            font-family: PingFang;
            font-weight: 500;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
