/**
 * 路由数组
 */
const menuList = [
  {
    icon: 'el-icon-setting menu-icon',
    title: '系统设置',
    sortIndex: '1',
    salesmanShow: false,
    children: [
      {
        path: '/system/user',
        title: '后台用户管理',
        sortIndex: '1-1',
      },
      {
        path: '/system/sendType',
        title: '快递方式管理',
        sortIndex: '1-2',
      },
      {
        path: '/system/systemSetting',
        title: '系统参数配置',
        sortIndex: '1-3',
      },
      {
        path: '/system/banner',
        title: 'banner管理',
        sortIndex: '1-4',
      },
      {
        path: '/system/label',
        title: '标签管理',
        sortIndex: '1-5',
      },
      {
        path: '/system/erp',
        title: 'erp设置',
        sortIndex: '1-6',
      },
      {
        path: '/system/brand',
        title: '品牌设置',
        sortIndex: '1-7',
      },
    ],
  },
  {
    icon: 'el-icon-menu menu-icon',
    title: '商品管理',
    sortIndex: '2',
    children: [
      {
        path: '/goods/list',
        title: '商品列表',
        sortIndex: '2-1',
        salesmanShow: false,
      },
      {
        path: '/goods/order',
        title: '订单列表',
        sortIndex: '2-2',
      },
    ],
  },
  {
    icon: 'el-icon-user menu-icon',
    title: '用户管理',
    sortIndex: '3',
    salesmanShow: false,
    children: [
      {
        path: '/user/list',
        title: '用户列表',
        sortIndex: '3-1',
      },
    ],
  },
  // {
  //   icon: 'el-icon-pie-chart menu-icon',
  //   title: '报表系统',
  //   sortIndex: '4',
  //   children: [
  //     {
  //       path: '/reportForm/salesStatistics',
  //       title: '商品销售统计汇总',
  //       sortIndex: '4-1',
  //     },
  //   ],
  // },
  {
    icon: 'el-icon-tickets menu-icon',
    title: '分销商管理',
    sortIndex: '4',
    children: [
      {
        path: '/distributor/list',
        title: '分销商列表',
        sortIndex: '4-1',
      },
      {
        path: '/distributor/level',
        title: '充值等级管理',
        sortIndex: '4-2',
        salesmanShow: false,
      },
    ],
  },
  {
    icon: 'el-icon-s-shop menu-icon',
    title: '品牌管理',
    sortIndex: '5',
    salesmanShow: false,
    children: [
    ],
  },
  {
    icon: 'el-icon-wallet menu-icon',
    title: '优惠券管理',
    sortIndex: '6',
    salesmanShow: false,
    children: [
      {
        path: '/coupon/list',
        title: '优惠券列表',
        sortIndex: '6-1',
      },
      {
        path: '/coupon/record',
        title: '发券记录列表',
        sortIndex: '6-2',
      },
    ],
  },
  {
    icon: 'el-icon-bank-card menu-icon',
    title: '优惠卡管理',
    sortIndex: '7',
    children: [
      {
        path: '/couponcard/list',
        title: '优惠卡列表',
        sortIndex: '7-1',
        salesmanShow: false,
      },
      {
        path: '/couponcard/activations',
        title: '兑换卡片激活明细',
        sortIndex: '7-2',
      },
    ],
  },
  {
    icon: 'el-icon-present menu-icon',
    title: '活动管理',
    sortIndex: '8',
    salesmanShow: false,
    children: [
      {
        path: '/operation/package',
        title: '套餐管理',
        sortIndex: '8-1',
      },
    ],
  },
  {
    icon: 'el-icon-s-order menu-icon',
    title: '财务报表',
    sortIndex: '9',
    salesmanShow: false,
    children: [
      {
        path: '/finance/export',
        title: '财务报表',
        sortIndex: '9-1',
      },
      {
        path: '/finance/withdrawal',
        title: '提现申请',
        sortIndex: '9-2',
      },
    ],
  },
];
const state = {
  list: menuList,
};

const mutations = {
  UPDATE_BRAND_LIST: (state, list) => {
    if (!list || list.length == 0) {
      return;
    }
    const children = list.map((item, index) => {
      return {
        path: '/brand/detail?id=' + item.brandId,
        title: item.brandName,
        sortIndex: '5-' + item.brandId,
      };
    })
    state.list[4].children = children;
  },
};

const actions = {
  updateBrandList({ commit }, list) {
    commit('UPDATE_BRAND_LIST', list);
  }
};

export default {
  moduleName: 'menuList',
  namespaced: true,
  state,
  mutations,
  actions,
};
