<template>
  <el-aside :width="isCollapse ? '64px' : '230px'">
    <div class="side-header">
      <img
        v-if="!isCollapse"
        class="side-logo"
        src="../../../assets/image/logo.png"
        alt="logo"
      />
      <Hamburger :is-active="!isCollapse" @toggleClick="toggleClick" />
    </div>
    <div class="side-menu">
      <el-menu
        v-if="menuList.length > 0"
        :collapse="isCollapse"
        :collapse-transition="false"
        :router="true"
        :default-active="currentMenu"
      >
        <template v-for="route in menuList">
          <!-- :popper-append-to-body="false" -->
          <template v-if="!route.children || route.children.length == 0">
            <el-menu-item
              :index="route.sortIndex"
              :key="route.path"
              :route="route.path"
            >
              <template slot="title">
                <i :class="route.icon"></i>
                <span>{{ route.title }}</span>
              </template>
            </el-menu-item>
          </template>
          <template v-if="route.children && route.children.length > 0">
            <el-submenu
              :index="route.sortIndex"
              :key="route.path"
            >
              <template slot="title">
                <i :class="route.icon"></i>
                <span>{{ route.title }}</span>
              </template>
              <el-menu-item
                v-for="item in route.children"
                :index="item.sortIndex"
                :key="item.path"
                :route="item.path"
              >
                {{ item.title }}
              </el-menu-item>
            </el-submenu>
          </template>
        </template>
      </el-menu>
    </div>
  </el-aside>
</template>

<script>
import { mapState } from 'vuex';
import Hamburger from '@/components/Hamburger';

function filterMenuList(list) { // 过滤业务员不展示的菜单
  const array = []
  for (let i = 0; i < list.length; i++) {
    if (list[i].salesmanShow !== false) {
      if (list[i].children && list[i].children.length > 0) {
        list[i].children = filterMenuList(list[i].children)
      }
      array.push(list[i])
    } 
  }
  return array
}

export default {
  name: 'SideBar',
  components: {
    Hamburger,
  },
  data() {
    return {
      currentMenu: ''
    };
  },
  mixins: [],
  watch:{
    $route(to) {
      console.log(to)
      let sortIndex = to.meta.sortIndex
      if (to.name === 'brandList') {
        const id = to.query.id || '1'
        sortIndex += id
      }
      this.currentMenu = sortIndex
    }
  },
  computed: {
    ...mapState({
      menuList: (state) => {
        const roleId = state.user.userInfo.roleId
        const list = JSON.parse(JSON.stringify(state.menuList.list))
        if (roleId === 3) {
          return filterMenuList(list)
        } else {
          return list
        }
      },
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
    }),
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
  created() {
    let sortIndex = this.$route.meta.sortIndex
      if (this.$route.name === 'brandList') {
        const id = this.$route.query.id || '1'
        sortIndex += id
      }
      this.currentMenu = sortIndex
  },
  methods: {
    toggleClick() {
      this.$store.dispatch('app/toggleSideBar');
    },
  },
};
</script>

<style lang="scss" scoped>
$colorprimary: #5767fa;
$menubg: #f7f7ff;

.el-aside {
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  user-select: none;
  .side-header {
    position: relative;
    height: 79px;
    border-bottom: 1px solid #f5f5f5;
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .side-logo {
      position: relative;
      height: 36px;
      width: auto;
      display: block;
    }
  }

  .side-menu {
    position: relative;
    flex: 1;
    overflow-y: overlay;
    .el-menu {
      border-right: 0;
      transition: width 0.1s;
      -webkit-transition: width 0.1s;
      -moz-transition: width 0.1s;
      -webkit-transition: width 0.1s;
      -o-transition: width 0.1s;
    }
  }
  /deep/ .el-menu--collapse .el-submenu__title span {
    display: none;
  }
  /deep/ .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
    display: none;
  }
  /deep/ .el-submenu__title:hover {
    color: $colorprimary;
    .menu-icon {
      color: $colorprimary;
    }
    .el-submenu__icon-arrow {
      color: $colorprimary;
    }
  }
  /deep/ .el-menu-item:hover {
    outline: 0 !important;
    color: $colorprimary !important;
    background-color: $menubg !important;
    .menu-icon {
      color: $colorprimary !important;
    }
  }
  /deep/ .el-menu-item.is-active {
    color: $colorprimary !important;
    background: $menubg !important;
    .menu-icon {
      color: $colorprimary !important;
    }
  }
}
</style>
