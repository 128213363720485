import Layout from '@/layout';

export default {
  path: '/user',
  name: 'user',
  component: Layout,
  redirect: '',
  meta: {
    title: '用户管理',
    icon: 'el-icon-user',
    sortIndex: '3',
  },
  children: [
    {
      path: 'list',
      name: 'userList',
      component: () => import('@/pages/user/list'),
      meta: {
        fatherTitle: '用户管理',
        title: '用户列表',
        sortIndex: '3-1',
      },
    },
    {
      path: 'detail',
      name: 'userDetail',
      component: () => import('@/pages/user/detail'),
      meta: {
        fatherTitle: '用户管理',
        title: '用户详情',
        sortIndex: '3-2',
      },
    }
  ],
};
