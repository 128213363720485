import Layout from '@/layout';

export default {
  path: '/reportForm',
  name: 'reportForm',
  component: Layout,
  redirect: '',
  meta: {
    title: '报表系统',
    icon: 'el-icon-menu',
    sortIndex: '4',
  },
  children: [
    {
      path: 'salesStatistics',
      name: 'salesStatistics',
      component: () => import('@/pages/reportForm/salesStatistics'),
      meta: {
        fatherTitle: '报表系统',
        title: '商品销售统计汇总',
        sortIndex: '4-1',
      },
    },
  ],
};
