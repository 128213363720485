import Vue from 'vue';
import Router from 'vue-router';
// 整体页面布局
import Layout from '@/layout';
// import { title as projectTitle } from '@/settings';
import Login from '@/pages/login';
import Home from '@/pages/home';

// import store from '@/store';
// 动态导入 router/modules 下所有路由文件
const files = require.context('./modules', true, /\.js$/, 'sync');
const routerModules = [];
files.keys().forEach((key) => {
  routerModules.push(files(key).default);
});

Vue.use(Router);
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
// 固定路由，不需要权限控制的
export const constantRoutes = [
  // 根路由，如果此路由不需要导航头部显示，父路由重定向到显示路由，子路由请勿设置空，
  {
    path: '/',
    component: Layout,
    redirect: '/home',
  },
  {
    path: '/home',
    component: Layout,
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
          title: '',
        },
      },
    ],
  },
  ...routerModules,
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/404',
    component: () => import('@/pages/404.vue'),
  },
  {
    path: '/401',
    component: () => import('@/pages/401.vue'),
  },
  {
    path: '/*',
    component: () => import('@/pages/404.vue'),
  },
];
// 需要权限的路由 启用 @/permission 时生效
export const asyncRoutes = [{ path: '*', redirect: '/404', hidden: true }];
const createRouter = () => {
  return new Router({
    base: '/',
    mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });
};

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
