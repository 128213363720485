/**
 * 商品管理 页面 api 请求
 */
 import carezHttp from '@/utils/request';

 /**
 * 获取商品列表
 * @param {*} params
 * @returns
 */
const getGoodsList = (httpParams) => {
  return carezHttp({
    url: `manager/goods/list`,
    method: 'POST',
    data: httpParams,
  });
};

/**
 * 获取订单列表
 */
const getOrderList = (httpParams) => {
  return carezHttp({
    url: `manager/order/list`,
    method: 'POST',
    data: httpParams,
  });
};

/**
 * 订单退款
 * @param {*} params 
 */
const orderRefund = (params) => {
  return carezHttp({
    url: `manager/order/doRefund`,
    method: 'POST',
    customTips: true,
    data: params,
  });
}

/**
 * 导出订单
 * @returns 
 */
const exportOrder = (params = {}) => {
  return carezHttp({
    url: `manager/order/export`,
    method: 'POST',
    data: params,
    responseType: 'blob'
  });
};

/**
 * 更新订单补充说明
 * @param {*} params 
 * @returns 
 */
const updateOrderTips = (params) => {
  return carezHttp({
    url: `manager/order/updateOrderTips`,
    method: 'POST',
    data: params,
  });
};

/**
 * 手动发货
 * @param {*} params 
 * @returns 
 */
const manualDelivery = (params) => {
  return carezHttp({
    url: `manager/order/deliver`,
    method: 'POST',
    data: params,
  });
};


/**
 * 新增商品
 * @param {*} params 
 * @returns 
 */
const addGoods = (params) => {
  return carezHttp({
    url: `manager/goods/addGoods`,
    method: 'POST',
    data: params,
  });
};

/**
 * 删除商品
 */
const deleteGoods = (goodsId) => {
  return carezHttp({
    url: `manager/goods/delete/${goodsId}`,
    method: 'POST',
    data: {},
  });
};

/**
 * 获取指定商品详情
 * @param {*} goodsId 
 * @returns 
 */
const getGoodsInfo = (goodsId) => {
  return carezHttp({
    url: `manager/goods/info/${goodsId}`,
    method: 'GET',
  });
};

/**
 * 更新商品信息
 * @param {*} params 
 * @returns 
 */
const updateGoods = (params) => {
  return carezHttp({
    url: `manager/goods/updateGoods`,
    method: 'POST',
    data: params,
  });
};

/**
 * 获取所有标签
 */
const getAllCategory = () => {
  return carezHttp({
    url: `manager/category/listAddCategories`,
    method: 'GET',
  });
}

export {
  getGoodsList,
  getOrderList,
  orderRefund,
  exportOrder,
  updateOrderTips,
  manualDelivery,
  addGoods,
  deleteGoods,
  getGoodsInfo,
  updateGoods,
  getAllCategory
}
