import Layout from '@/layout';

export default {
  path: '/goods',
  name: 'goods',
  component: Layout,
  redirect: '',
  meta: {
    title: '商品管理',
    icon: 'el-icon-menu',
    sortIndex: '2',
  },
  children: [
    {
      path: 'list',
      name: 'goodsList',
      component: () => import('@/pages/goods/list'),
      meta: {
        fatherTitle: '商品管理',
        title: '商品列表',
        sortIndex: '2-1',
      },
    },
    {
      path: 'order',
      name: 'goodsOrder',
      component: () => import('@/pages/goods/order'),
      meta: {
        fatherTitle: '商品管理',
        title: '订单列表',
      },
    },
  ],
};
