import Layout from '@/layout';

export default {
  path: '/coupon',
  name: 'coupon',
  component: Layout,
  redirect: '',
  meta: {
    title: '优惠券管理',
    icon: 'el-icon-menu',
    sortIndex: '6',
  },
  children: [
    {
      path: 'list',
      name: 'couponList',
      component: () => import('@/pages/coupon/list'),
      meta: {
        fatherTitle: '优惠券管理',
        title: '优惠券列表',
        sortIndex: '6-1',
      },
    },
    {
      path: 'record',
      name: 'recordList',
      component: () => import('@/pages/coupon/record'),
      meta: {
        fatherTitle: '优惠券管理',
        title: '发券记录列表',
        sortIndex: '6-2',
      },
    },
  ],
};
