<template>
  <div class="hamburger-wrap" @click="toggleClick">
    <img
      src="../../assets/image/hamburger.png"
      :class="{ 'is-active': isActive }"
      class="hamburger"
    />
  </div>
</template>

<script>
export default {
  name: 'Hamburger',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleClick() {
      this.$emit('toggleClick');
    },
  },
};
</script>

<style scoped>
.hamburger-wrap {
  padding: 10px;
  cursor: pointer;
}
.hamburger {
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 11px;
  transform: rotate(180deg);
}

.hamburger.is-active {
  transform: rotate(0deg);
}
</style>
