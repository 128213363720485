/**
 * 优惠券 页面 api 请求
 */
 import carezHttp from '@/utils/request';

/**
 * 获取优惠券列表
 * @param {*} id 
 * @returns 
 */
 const getCouponList = (params) => {
  return carezHttp({
    url: `manager/coupon/list`,
    method: 'post',
    data: params,
  });
}

/**
 * 获取发券记录列表
 * @returns 
 */
const getRecordList = (params) => {
  return carezHttp({
    url: `manager/coupon/send/list`,
    method: 'post',
    data: params,
  });
}

/**
 * 删除发券记录
 * @param {*} sendCouponId 
 * @returns 
 */
const deleteCategory = (sendCouponId) => {
  if (!sendCouponId) {
    return;
  }
  return carezHttp({
    url: `manager/coupon/delete/${sendCouponId}`,
    method: 'post',
    data: {},
  });
};

const deleteSendCategory = (sendCouponId) => {
  if (!sendCouponId) {
    return;
  }
  return carezHttp({
    url: `manager/coupon/send/delete/${sendCouponId}`,
    method: 'post',
    data: {},
  });
};

/**
 * 发券
 * @param {*} parmas 
 * @returns 
 */
const addCoupon = (parmas) => {
  return carezHttp({
    url: `manager/coupon/add`,
    method: 'post',
    data: parmas,
  })
};

/**
 * 获取发券时目标用户下拉框列表
 * @returns 
 */
const getCouponTargetList = () => {
  return carezHttp({
    url: `manager/coupon/getCouponTargetList`,
    method: 'get',
  });
}

export {
  getCouponList,
  getRecordList,
  deleteCategory,
  addCoupon,
  getCouponTargetList,
  deleteSendCategory,
}