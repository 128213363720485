/**
 * 分销商 页面 api 请求
 */
import carezHttp from '@/utils/request';

/**
 * 获取分销商列表
 * @param {*} params
 * @returns
 */
const getDistributorList = (httpParams) => {
  return carezHttp({
    url: `manager/distributor/list`,
    method: 'POST',
    data: httpParams,
  });
};

/**
 * 所有等级列表
 */
const getLevelList = (levelType) => {
  levelType = levelType || 1;
  return carezHttp({
    url: `manager/level/list/${levelType}`,
    method: 'GET',
  });
};

/**
 * 更新等级信息
 * @param {*} params 
 * @returns 
 */
const updateLevel = (params) => {
  return carezHttp({
    url: `manager/level/update`,
    method: 'POST',
    data: params,
  });
};

/**
 * 获取分销商详情
 */
const getDistributorDetail = (id) => {
  return carezHttp({
    url: `manager/distributor/info/${id}`,
    method: 'GET',
  });
};

/**
 * 获取用户充值记录列表
 */
const getRechargeRecord = (id, params) => {
  return carezHttp({
    url: `manager/distributor/listRechargeRecord/${id}`,
    method: 'POST',
    data: params
  });
};

/**
 * 设置分销商等级
 * @param {*} params 
 * @returns 
 */
const setDistributorLevel = (params) => {
  return carezHttp({
    url: `manager/distributor/updateLevel`,
    method: 'POST',
    data: params,
  });
};

/**
 * 锁定分销商等级
 * @param {*} params 
 */
const updateLocked = (params) => {
  return carezHttp({
    url: `manager/distributor/updateLocked`,
    method: 'POST',
    data: params,
  });
};

/**
 * 设置经营类型
 * @param {*} params 
 */
const updateBusinessType = (params) => {
  return carezHttp({
    url: `manager/distributor/updateBusinessType`,
    method: 'POST',
    data: params,
  });
};

/**
 * 更新业务员
 * @param {*} params 
 */
const updateSalesman = (params) => {
  return carezHttp({
    url: `manager/distributor/updateSalesman`,
    method: 'POST',
    data: params,
  });
};

/**
 * 分销商充值
 * @param {*} params 
 */
const recharge = (params) => {
  return carezHttp({
    url: `manager/distributor/recharge`,
    method: 'POST',
    data: params,
  });
};

/**
 * 授权品牌
 * @param {*} params 
 */
const setBrands = (params) => {
  return carezHttp({
    url: `manager/distributor/setBrands`,
    method: 'POST',
    data: params,
  });
};

/**
 * 设置分销商内购类型
 * @param {*} params 
 */
 const updateInnerUserType = (params) => {
  return carezHttp({
    url: `manager/distributor/updateInnerUserType`,
    method: 'POST',
    data: params,
  });
};

/**
 * 设置内购用户月限额
 * @param {*} params 
 */
 const updateUserMonthLimitAmt = (params) => {
  return carezHttp({
    url: `manager/distributor/updateUserMonthLimitAmt`,
    method: 'POST',
    data: params,
  });
};

/**
 * 设置是否账期
 * @param {*} params 
 */
const updateCreditable = (params) => {
  return carezHttp({
    url: `manager/distributor/updateCreditable`,
    method: 'POST',
    data: params,
  });
};

export {
  getDistributorList,
  getLevelList,
  updateLevel,
  getDistributorDetail,
  getRechargeRecord,
  setDistributorLevel,
  updateLocked,
  updateBusinessType,
  updateSalesman,
  recharge,
  setBrands,
  updateInnerUserType,
  updateUserMonthLimitAmt,
  updateCreditable,
};
