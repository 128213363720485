import Layout from '@/layout';

export default {
  path: '/brand',
  name: 'brand',
  component: Layout,
  redirect: '',
  meta: {
    title: '',
    icon: 'el-icon-menu',
    sortIndex: '5',
  },
  children: [
    {
      path: 'detail',
      name: 'brandList',
      component: () => import('@/pages/brand/detail'),
      meta: {
        fatherTitle: '品牌管理',
        title: '品牌详情',
        sortIndex: '5-',
      },
    },
  ],
};
