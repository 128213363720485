import Layout from '@/layout';

export default {
  path: '/system',
  name: 'system',
  component: Layout,
  meta: {
    title: '系统设置',
    icon: 'el-icon-setting',
    sortIndex: '1',
  },
  children: [
    {
      path: 'user',
      name: 'systemUser',
      component: () => import('@/pages/system/user'),
      meta: {
        fatherTitle: '系统设置',
        title: '后台用户管理',
        sortIndex: '1-1',
      },
    },
    {
      path: 'sendType',
      name: 'systemSendType',
      component: () => import('@/pages/system/sendType'),
      meta: {
        fatherTitle: '系统设置',
        title: '快递方式管理',
        sortIndex: '1-2',
      },
    },
    {
      path: 'systemSetting',
      name: 'systemSetting',
      component: () => import('@/pages/system/systemSetting'),
      meta: {
        fatherTitle: '系统设置',
        title: '系统参数配置',
        sortIndex: '1-3',
      },
    },
    {
      path: 'banner',
      name: 'systemBanner',
      component: () => import('@/pages/system/banner'),
      meta: {
        fatherTitle: '系统设置',
        title: 'banner管理',
        sortIndex: '1-4',
      },
    },
    {
      path: 'label',
      name: 'systemLabel',
      component: () => import('@/pages/system/label'),
      meta: {
        fatherTitle: '系统设置',
        title: '标签管理',
        sortIndex: '1-5',
      },
    },
    {
      path: 'erp',
      name: 'systemErp',
      component: () => import('@/pages/system/erp'),
      meta: {
        fatherTitle: '系统设置',
        title: 'erp设置',
        sortIndex: '1-6',
      },
    },
    {
      path: 'brand',
      name: 'systemBrand',
      component: () => import('@/pages/system/brand'),
      meta: {
        fatherTitle: '系统设置',
        title: '品牌设置',
        sortIndex: '1-7',
      },
    },
  ],
};
