import Cookies from 'js-cookie';
// import { resolve } from 'path';
import { getSalesman } from '@/apis/common';
import { Message } from 'element-ui';

const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus')
      ? !!+Cookies.get('sidebarStatus')
      : true,
    withoutAnimation: false,
  },
  device: 'desktop',
  size: Cookies.get('size') || 'medium',
  brandList: [],
  brandLevels: [],
  salesman: [],
  distributorPage: 1,
};

const mutations = {
  TOGGLE_SIDEBAR: (state) => {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device;
  },
  SET_SIZE: (state, size) => {
    state.size = size;
    Cookies.set('size', size);
  },
  SET_BRANDLIST: (state, list) => {
    state.brandList = list;
  },
  SET_BRANDLEVELS: (state, list) => {
    state.brandLevels = list;
  },
  SET_SALESMAN: (state, salesman) => {
    state.salesman = salesman;
  },
  SET_DISTRIBUTORPAGE: (state, distributorPage) => {
    state.distributorPage = distributorPage;
  },
};

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device);
  },
  setSize({ commit }, size) {
    commit('SET_SIZE', size);
  },
  setBrandList({ commit }, list) {
    commit('SET_BRANDLIST', list);
  },
  setBrandLevels({ commit }, list) {
    commit('SET_BRANDLEVELS', list);
  },
  setSalesMan({ commit }) {
    return new Promise((resolve, reject) => {
      getSalesman().then(res => {
        if (res.code === 0) {
          const { data } = res;
          commit('SET_SALESMAN', data);
        } else {
          Message.error(res.msg);
        }
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  },
  setDitributorPage({ commit }, distributorPage) {
    commit('SET_DISTRIBUTORPAGE', distributorPage);
  },
};

export default {
  moduleName: 'app',
  namespaced: true,
  state,
  mutations,
  actions,
};
