/**
 * login 页面 api 请求
 */
import carezHttp from '@/utils/request';
import { AESUtil } from '@/utils/common';

const login = (params) => {
  const httpParams = {
    userName: params.userName,
    password: AESUtil.aesEncrypt(params.password),
  };
  return carezHttp({
    url: `manager/login`,
    method: 'POST',
    data: httpParams,
  });
};

const a = () => {};

export { login, a };
