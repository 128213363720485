<template>
  <el-container class="app-wrapper">
    <template v-if="showPage">
      <SideBar />
      <div class="main-container">
        <AppHeader />
        <AppMain />
      </div>
    </template>
    <template v-if="!showPage">
      <el-skeleton />
    </template>
  </el-container>
</template>

<script>
import { mapState } from 'vuex';
import cache from '@/utils/cache';
import SideBar from './components/Sidebar';
import AppMain from './components/AppMain';
import AppHeader from './components/AppHeader';
import { checkLogin } from '@/utils/common';
import { getBrandMenuList } from '@/apis/common';
import { getEnableLevels } from '@/pages/brand/apis';

export default {
  name: 'Layout',
  components: {
    SideBar,
    AppMain,
    AppHeader,
  },
  data() {
    return {
      showPage: false,
    };
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
    }),
  },
  created() {
    this.$store.dispatch('app/setSalesMan');
  },
  mounted() {
    // check 登录状态
    if (!checkLogin()) {
      this.$router.push('/login');
      return;
    }
    this.getBrandMenuList();
    const userInfo = cache.get('carez-userInfo');
    if (userInfo) {
      this.$store.dispatch('user/setUsers', userInfo);
    }
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false });
    },
    async getBrandMenuList() {
      const context = this;
      const res = await getBrandMenuList();
      const levels = await getEnableLevels();
      const { code, data = [], message } = res;
      if (code === 0) {
        await this.$store.dispatch('menuList/updateBrandList', data);
        await this.$store.dispatch('app/setBrandList', data);
      } else {
        context.$message({
          message: message || data.message || '获取品牌商失败',
          type: 'error',
        });
      }
      const { code: levelCode, data: levelList, message: errMsg } = levels;
      if (levelCode === 0) {
        await this.$store.dispatch('app/setBrandLevels', levelList).then(() => {
          context.showPage = true;
        });
      } else {
        context.$message({
          message: errMsg,
          type: 'error',
        });
        context.showPage = true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  position: relative;
  height: 100vh;
  width: 100%;
  .main-container {
    height: 100vh;
    position: relative;
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    overflow: hidden;
  }
  /deep/ .carez-choose .el-input {
    width: auto;
    max-width: 200px;
    min-width: 100px;
  }
  /deep/ .el-form--label-top .el-form-item__label {
    padding-bottom: 0px !important;
    line-height: 2;
  }
}
</style>
