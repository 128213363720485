/**
 * 销售数据统计 页面 api 请求
 */
import carezHttp from '@/utils/request';

/**
 * 获取分销商列表
 * @param {*} params
 * @returns
 */
const getSalesStatistics = (params = {}) => {
  return carezHttp({
    url: `manager/statistics/order`,
    method: 'POST',
    data: params,
  });
};

/**
 * 导出统计数据
 * @returns
 */
const getStatisticsExcel = (params = {}) => {
  let url = 'manager/order/export';
  return carezHttp({
    url: url,
    method: 'POST',
    data: params,
    responseType: 'blob'
  });
};

export { getSalesStatistics, getStatisticsExcel };
