import Vue from 'vue';
// 引入公共接口库
import ELEMENT from 'element-ui';
import Fragment from 'vue-fragment';
import App from './App.vue';

import service from '@/utils/request';
import router from './router/index';
import store from './store/index';
import '@/styles/common/reset-element.scss';
// import permission from './directive/permission';

import baseConfig from '../config';

Vue.use(ELEMENT);
Vue.use(Fragment.Plugin);

Vue.prototype.$http = service;
Vue.prototype.baseConfig = baseConfig;

Vue.config.productionTip = false;

window.globalVue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
