/**
 * 品牌设置 api 请求
 */
import carezHttp from '@/utils/request';

/**
 * 获取品牌可用等级列表
 * @param {*} id 
 * @returns 
 */
const getEnableLevels = () => {
  return carezHttp({
    url: `manager/level/listEnableLevels`,
    method: 'get',
  });
};

/**
 * 查询品牌等级统一折扣
 * @param {*} brandId 
 * @param {*} levelCode 
 * @returns 
 */
const getUnifiedDiscount = (brandId, levelCode) => {
  return carezHttp({
    url: `manager/brand/discount/${brandId}/${levelCode}`,
    method: 'get',
  });
};

/**
 * 更新品牌等级统一折扣
 * @param {*} params 
 * @returns 
 */
const updateUnifiedDiscount = (params) => {
  return carezHttp({
    url: `manager/brand/updateUnifiedDiscount`,
    method: 'post',
    data: params
  });
};

/**
 * 获取品牌下的商品及折扣列表
 * @param {*} params 
 * @returns 
 */
const getBrandGoods = (params) => {
  return carezHttp({
    url: `manager/brand/listGoodsDiscount`,
    method: 'POST',
    data: params
  });
};

/**
 * 批量更新品牌商品等级折扣
 * @param {*} params 
 * @returns 
 */
const updateBatchBrandGoodsDiscount = (params) => {
  return carezHttp({
    url: `manager/brand/updateBatchBrandGoodsDiscount`,
    method: 'POST',
    data: params
  });
}


export {
  getEnableLevels,
  getUnifiedDiscount,
  updateUnifiedDiscount,
  getBrandGoods,
  updateBatchBrandGoodsDiscount,
}
