import Layout from '@/layout';

export default {
  path: '/operation',
  name: 'operation',
  component: Layout,
  redirect: '',
  meta: {
    title: ['活动管理'],
    titles: '活动管理',
    icon: 'el-icon-present',
    sortIndex: '8',
  },
  children: [
    {
      path: 'package',
      name: 'package',
      component: () => import('@/pages/operation/package/index'),
      meta: {
        fatherTitle: '活动管理',
        title: '套餐管理',
        sortIndex: '8-1',
      },
    },
    {
      path: 'addPackage',
      name: 'addPackage',
      component: () => import('@/pages/operation/package/add'),
      meta: {
        grandFatherTitle: '活动管理',
        fatherTitle: '套餐管理',
        title: '新增套餐',
        sortIndex: '8-1',
      },
    },
    {
      path: 'addGoods',
      name: 'addGoods',
      component: () => import('@/pages/operation/package/binding'),
      meta: {
        grandFatherTitle: '活动管理',
        fatherTitle: '套餐管理',
        title: '编辑套餐',
        titles: ['活动管理', '套餐管理', '新增套餐', '绑定商品'],
        sortIndex: '8-1',
      },
    },
    {
      path: 'editPackage',
      name: 'editPackage',
      component: () => import('@/pages/operation/package/add'),
      meta: {
        grandFatherTitle: '活动管理',
        fatherTitle: '套餐管理',
        title: '编辑套餐',
        sortIndex: '8-1',
      },
    },
    {
      path: 'bindingGoods',
      name: 'bindingGoods',
      component: () => import('@/pages/operation/package/binding'),
      meta: {
        grandFatherTitle: '活动管理',
        fatherTitle: '套餐管理',
        title: '编辑套餐',
        titles: ['活动管理', '套餐管理', '编辑套餐', '绑定商品'],
        sortIndex: '8-1',
      },
    },
  ],
};
