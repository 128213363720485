import { getToken } from '@/utils/common';

const state = {
  token: getToken(),
  userInfo: {},
};

const mutations = {
  SET_USERS(state, users) {
    state.userInfo = users;
  },
};

const actions = {
  setUsers({ commit }, data) {
    commit('SET_USERS', data || {});
  },
};

export default {
  moduleName: 'user',
  namespaced: true,
  state,
  mutations,
  actions,
};
