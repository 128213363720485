/**
 * 用户管理 页面 api 请求
 */
import carezHttp from '@/utils/request';

/**
* 获取用户列表
* @param {*} params
* @returns
*/
const getUserList = (params) => {
  return carezHttp({
    url: `manager/wechat/user/list`,
    method: 'POST',
    data: params,
  });
};

/**
 * 设置用户类型
 * @param {*} params 
 * @returns 
 */
const updateUserType = (params) => {
  return carezHttp({
    url: `manager/wechat/user/updateUserType`,
    method: 'POST',
    data: params,
  });
};

/**
 * 获取用户信息
 * @param {*} userId 
 * @returns 
 */
const getUserDetail = (userId) => {
  return carezHttp({
    url: `manager/wechat/user/info/${userId}`,
    method: 'GET',
  });
};


/**
 * 查询用户订单列表
 * @param {*} params 
 * @returns 
 */
const getUserOrder = (params) => {
  return carezHttp({
    url: `manager/wechat/user/listOrders`,
    method: 'POST',
    data: params,
  });
}

export {
  getUserList,
  updateUserType,
  getUserDetail,
  getUserOrder,
};
 