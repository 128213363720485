/*
 * @Descripttion: 全局状态管理处理
 * @version: v1.0.0
 * @Author: huangjunjian
 * @email: huangjunjian@longfor.com
 * @Date: 2021-09-07 14:50:40
 * @LastEditors: huangjunjian
 * @LastEditTime: 2021-09-28 18:32:23
 */

import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';

Vue.use(Vuex);

const files = require.context('../', true, /\.js$/);
const reduceFiles = files.keys().filter((item) => {
  const globalStoreReg = /^(\.\/store\/modules)[A-Za-z/]+\.js$/;
  const pagesStoreReg = /^(\.\/pages)[A-Za-z/]+(\/_store\.js)$/;
  // 兼容Windows、mac正则(正反斜杠)，已在mock文件统一处理
  // const globalStoreReg = /^(\.(\/|\\)store(\/|\\)modules)[A-Za-z/|\\]+\.js$/;
  // const pagesStoreReg = /^(\.(\/|\\)pages)[A-Za-z/|\\]+(\/|\\_store\.js)$/;
  return globalStoreReg.test(item) || pagesStoreReg.test(item);
});

const modules = reduceFiles.reduce((store, modulePath) => {
  const obj = store;
  const module = files(modulePath).default;
  obj[module.moduleName] = module;
  return obj;
}, {});

const store = new Vuex.Store({
  getters,
  modules,
});

export default store;
