<template>
  <div class="app-header">
    <div class="header-breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right" v-if="titles.length > 0">
        <el-breadcrumb-item v-for="(title, index) in titles" :key="index">{{ title }}</el-breadcrumb-item>
      </el-breadcrumb>
      <el-breadcrumb separator-class="el-icon-arrow-right" v-else>
        <el-breadcrumb-item v-if="grandFatherTitle">{{ grandFatherTitle }}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="fatherTitle">{{ fatherTitle }}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="pageTitle">{{ pageTitle }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="header-user">
      <div class="header-user-tip">欢迎你，</div>
      <div class="header-user-tab">
        <el-dropdown @command="handleCommand">
          <span v-if="userInfo" class="el-dropdown-link"> {{ userInfo.realName }} </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="forgetPass">忘记密码</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { forgetPass, logout } from '@/apis/common';

export default {
  name: 'AppHeader',
  components: {},
  data() {
    return {};
  },
  mixins: [],
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
      userInfo: (state) => state.user.userInfo,
    }),
    grandFatherTitle() {
      return this.$route?.meta?.grandFatherTitle || '';
    },
    fatherTitle() {
      return this.$route?.meta?.fatherTitle || '';
    },
    pageTitle() {
      return this.$route?.meta?.title || '';
    },
    titles() {
      return this.$route?.meta?.titles || [];
    },
  },
  methods: {
    handleCommand(command) {
      if (command === 'forgetPass') {
        forgetPass();
      } else if (command === 'logout') {
        logout();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-header {
  position: relative;
  height: 80px;
  padding: 0 30px 0 15px;
  flex-shrink: 0;
  background-color: #fff;
  border-left: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header-breadcrumb {
    position: relative;
    /deep/ .el-breadcrumb__inner {
      color: #131519;
    }
  }
  .header-user {
    position: relative;
    color: #181b23;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .el-dropdown-link {
      color: #181b23;
      cursor: pointer;
    }
  }
}
</style>
