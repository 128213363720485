import Vue from 'vue';
import { parse } from 'qs';
import union from 'lodash.union';
import moment from 'moment';
import * as mathjs from 'mathjs';
import CryptoJS from 'crypto-js';

const reg =
  /^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/;
const isString = (v) => typeof v === 'string';
export const isUrl = (path) => reg.test(path);

export const getPageQuery = () => parse(window.location.href.split('?')[1]);

// 获取cookie
export const getCookie = (name) => {
  const arr = document.cookie.match(new RegExp(`(^| )${name}=([^;]*)(;|$)`));
  if (arr != null) return unescape(arr[2]);
  return null;
};

// 根据身份证号得到姓别和精确计算年龄
export const analyzeIDCard = (IDCard) => {
  const sexAndAge = {};
  // 获取用户身份证号码
  const userCard = IDCard;
  // 如果身份证号码为undefind则返回空
  if (!userCard) {
    return sexAndAge;
  }
  // 获取性别
  if (parseInt(userCard.substr(16, 1), 10) % 2 === 1) {
    sexAndAge.sex = 'M';
    sexAndAge.sexNum = '1';
  } else {
    sexAndAge.sex = 'F';
    sexAndAge.sexNum = '2';
  }
  // 获取出生年月日
  // userCard.substring(6,10) + "-" + userCard.substring(10,12) + "-" + userCard.substring(12,14);
  const yearBirth = userCard.substring(6, 10);
  const monthBirth = userCard.substring(10, 12);
  const dayBirth = userCard.substring(12, 14);
  const birth = `${yearBirth}-${monthBirth}-${dayBirth}`;
  sexAndAge.birth = birth;
  // 获取当前年月日并计算年龄
  const myDate = new Date();
  // const monthNow = myDate.getMonth() + 1;
  // const dayNow = myDate.getDay();
  const age = myDate.getFullYear() - yearBirth;
  // 根据生日判断
  // if(monthNow < monthBirth || (monthNow === monthBirth && dayNow < dayBirth)){
  // 	age -= 1;
  // }
  // 得到年龄
  sexAndAge.age = age;
  // 返回性别和年龄
  return sexAndAge;
};

// 验证输入不能有空格
export const emptyRule = () => ({
  validator(rule, value) {
    if (value) {
      if (value.indexOf(' ') < 0) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('禁止输入空格'));
    }
    return Promise.resolve();
  },
});

// 验证只能输入英文字母和数字，且必须数字加字母
export const onlyEnglisOrNum = () => ({
  validator(rule, value) {
    if (value) {
      if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[A-Za-z0-9]+$/.test(value)) {
        return Promise.reject(new Error('请输入英文字母和数字'));
      }
    }
    return Promise.resolve();
  },
});

// 纯数字校验，允许0-9
export const onlyNum = () => ({
  validator(rule, value) {
    if (value) {
      if (!/^[0-9]+$/.test(value)) {
        return Promise.reject(new Error('请输入数字'));
      }
    }
    return Promise.resolve();
  },
});

// 数字输入框校验
export const numberLimit = (params) => ({
  validator(rule, value) {
    if (value) {
      if (params.negative && value < 0) {
        return Promise.reject(new Error('禁止输入负数'));
      }
      if ((params.min || params.min === 0) && value < params.min) {
        return Promise.reject(new Error(`输入值需大于${params.min}`));
      }
      if ((params.max || params.max === 0) && value > params.max) {
        return Promise.reject(new Error(`输入值需小于${params.max}`));
      }
      if (params.integer && !/^-?[1-9]\d*$/.test(value)) {
        return Promise.reject(new Error('请输入整数'));
      }
      return Promise.resolve();
    }
    return Promise.resolve();
  },
});

// 验证脱敏数据编辑校验（详情数据不改不校验）
export const tmValidator = (key, pattern, mesg) => ({
  validator(rule, value) {
    if (value && value !== key) {
      if (pattern.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(mesg));
    }
    return Promise.resolve();
  },
});

// 保留n位小数
export const formatNumDecimalMath = (num, n) => {
  return mathjs.BigNumber(num).toFixed(n);
};

const mathConfig = { precision: 14 };

// 小数加法
export const numberPlusMath = (a, b, config = mathConfig) => {
  return +mathjs.format(mathjs.add(a, b), config);
};

// 小数减法
export const numberMinusMath = (a, b, config = mathConfig) => {
  return +mathjs.format(mathjs.subtract(a, b), config);
};

// 小数乘法
export const numberMultiplyMath = (a, b, config = mathConfig) => {
  return +mathjs.format(mathjs.multiply(a, b), config);
};

// 小数除法
export const numberDivideMath = (a, b, config = mathConfig) => {
  return +mathjs.format(mathjs.divide(a, b), config);
};

// 去除对象的空数组（处理请求参数用）
export const emptyArrToUndefined = (obj = {}) => {
  const newObj = {};
  Object.keys(obj).forEach((k) => {
    const val = obj[k];
    newObj[k] = Array.isArray(val) && val.length === 0 ? undefined : val;
  });
  return newObj;
};

// 金额转大写(copy from baidu)
export const numToUppercase = (num) => {
  let money = num;
  const cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']; // 汉字的数字
  const cnIntRadice = ['', '拾', '佰', '仟']; // 基本单位
  const cnIntUnits = ['', '万', '亿', '兆']; // 对应整数部分扩展单位
  const cnDecUnits = ['角', '分', '毫', '厘']; // 对应小数部分单位
  const cnInteger = '整'; // 整数金额时后面跟的字符
  const cnIntLast = '元'; // 整型完以后的单位
  const maxNum = 999999999999999.9999; // 最大处理的数字
  let integerNum; // 金额整数部分
  let decimalNum; // 金额小数部分
  let chineseStr = ''; // 输出的中文金额字符串
  let parts; // 分离金额后用的数组，预定义
  if (money === '') {
    return '';
  }
  money = parseFloat(money);
  if (money >= maxNum) {
    return ''; // 超出最大处理数字
  }
  if (money === 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger;
    return chineseStr;
  }
  money = money.toString();
  if (money.indexOf('.') === -1) {
    integerNum = money;
    decimalNum = '';
  } else {
    parts = money.split('.');
    integerNum = parts[0] || '';
    decimalNum = parts[1].substr(0, 4);
  }
  // 获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    let zeroCount = 0;
    const IntLen = integerNum.length;
    for (let i = 0; i < IntLen; i += 1) {
      const n = integerNum.substr(i, 1);
      const p = IntLen - i - 1;
      const q = p / 4;
      const m = p % 4;
      if (n === '0') {
        zeroCount += 1;
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0];
        }
        zeroCount = 0; // 归零
        chineseStr += cnNums[+n] + cnIntRadice[m];
      }
      if (m === 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q];
      }
    }
    chineseStr += cnIntLast;
  }
  // 小数部分
  if (decimalNum !== '') {
    const decLen = decimalNum.length;
    for (let i = 0; i < decLen; i += 1) {
      const n = decimalNum.substr(i, 1);
      if (n !== '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
      }
    }
  }
  if (chineseStr === '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger;
  } else if (decimalNum === '') {
    chineseStr += cnInteger;
  }
  return chineseStr;
};

/**
 * 下载文件函数
 * @param {Object} response 下载请求返回数据
 */
export function download(res) {
  // 下载后台接口返回的二进制数据文件
  const blob = new Blob([res.data]); // 文件类型
  const a = document.createElement('a');
  const loadstream = window.URL.createObjectURL(blob);
  const contentDisposition = res.headers['content-disposition'] || '';
  const encodedFilename = contentDisposition.split('filename=')[1];
  if (!encodedFilename) return Vue.prototype.$message.error('导出失败');
  const filename = decodeURIComponent(encodedFilename);
  a.href = loadstream;
  a.download = filename;
  document.body.appendChild(a);
  if (navigator.userAgent.indexOf('Firefox') > -1) {
    // 火狐浏览器不支持click()
    const evt = document.createEvent('MouseEvents');
    evt.initEvent('click', true, true);
    a.dispatchEvent(evt);
  } else {
    a.click();
  }
  document.body.removeChild(a);
  return window.URL.revokeObjectURL(loadstream); // 释放掉blob对象
}

// 数组转枚举
export const arrToEnum = (items, valKey, descKey) =>
  (items || []).reduce(
    (cur, item) => ({
      [item[valKey]]: item[descKey],
      ...cur,
    }),
    {}
  );

// 兼容有的用的大写有的用的小写
export const ArrToEnum = arrToEnum;

// 枚举转数组
export const enumToArr = (enumData) =>
  Object.keys(enumData || {}).map((v) => ({
    value: v,
    label: enumData[v],
  }));

export const pickerOptions = [
  {
    text: '今天',
    onClick(picker) {
      const end = new Date();
      const start = new Date(new Date().toDateString());
      end.setTime(start.getTime());
      picker.$emit('pick', [start, end]);
    },
  },
  {
    text: '最近一周',
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit('pick', [start, end]);
    },
  },
  {
    text: '最近一个月',
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit('pick', [start, end]);
    },
  },
  {
    text: '最近三个月',
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit('pick', [start, end]);
    },
  },
];

export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  }
  return new Date(new Date().toDateString());
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 */
export function deepClone(source) {
  let targetObj;
  if (source && typeof source === 'object') {
    targetObj = source.constructor === Array ? [] : {};
    Object.keys(source).forEach((keys) => {
      if (source[keys] && typeof source[keys] === 'object') {
        targetObj[keys] = deepClone(source[keys]);
      } else {
        targetObj[keys] = source[keys];
      }
    });
  } else {
    throw new Error('error arguments', 'deepClone');
  }
  return targetObj;
}

export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

const ok = (status) => isString(status) && status.toLowerCase() === 'ok';

const isArray = (arr) => Array.isArray(arr);

const actionType = (namespace) => (type) => `${namespace}/${type}`;

const strKeyTransfer = (key) => {
  if (isString(key)) {
    return Number.isNaN(Number(key))
      ? {
          [key]: key,
          true: true,
          false: false,
        }[key]
      : Number(key);
  }
  return key;
};

const downloadWithUrl = (url, fileName, isBlank = false) => {
  const a = document.createElement('a');
  const fName = fileName || url.replace(/^.*\//, '');

  a.setAttribute('href', url);
  a.setAttribute('download', fName);
  if (isBlank) a.setAttribute('target', '_blank');
  a.style.setProperty('display', 'none');
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const previewFileWithUrl = (url) => {
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('target', '_blank');
  a.style.setProperty('display', 'none');
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const tidyAjaxData = (data, removeNull = false) => {
  const ret = {
    ...data,
  };
  Object.keys(ret).forEach((key) => {
    if (isString(ret[key]) && ret[key].trim() === '') delete ret[key];
    if (isString(ret[key]))
      ret[key] = {
        [ret[key]]: ret[key],
        false: false,
        true: true,
      }[ret[key]];
    if (removeNull && ret[key] === null) delete ret[key];
    if (Array.isArray(ret[key]) && !ret[key].length) delete ret[key];
  });
  return ret;
};

// 数组对象去重
const uniqBy = (arr, key) => {
  const dataList = arr || [];
  const uniqValues = Array.from(new Set(dataList.map((v) => v[key])));
  return uniqValues.map((v) => dataList.find((item) => item[key] === v));
};

// 数组对象去掉某些项
const pullAllBy = (arr, pullArr, key) => {
  return (arr || []).filter((item) => {
    return !pullArr.some((v) => v[key] === item[key]);
  });
};

// 校验有效身份证
const checkIDCard = (idcode) => {
  if (!idcode || idcode.length < 17) {
    return false;
  }
  // 加权因子
  const weightFactor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  // 校验码
  const checkCode = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];

  const code = `${idcode}`;
  const last = idcode[17]; // 最后一个

  const seventeen = code.substring(0, 17);

  // ISO 7064:1983.MOD 11-2
  // 判断最后一位校验码是否正确
  const arr = seventeen.split('');
  let num = 0;
  arr.forEach((item, index) => {
    num += item * weightFactor[index];
  });

  // 获取余数
  const resisue = num % 11;
  const lastNo = checkCode[resisue];

  const idcardPatter =
    /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;

  // 判断格式是否正确
  const format = idcardPatter.test(idcode);

  // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
  return last === lastNo && format;
};

// 判断两个数组中的元素是否完全一致
const isEqualArr = (arr1 = [], arr2 = []) => {
  if ((arr1 || []).length === (arr2 || []).length) {
    if ((union(arr1, arr2) || []).length === (arr1 || []).length) {
      return true;
    }
    return false;
  }
  return false;
};

// 设置token
const setToken = (token) => {
  localStorage.setItem('Authorization', token);
};

// 获取token
const getToken = () => {
  return localStorage.getItem('Authorization');
};

const removeToken = () => {
  localStorage.removeItem('Authorization');
};

const checkLogin = () => {
  const isLogin = getToken() ? 1 : 0;
  return isLogin;
};

// 大于等于0，小于等于100，最多两位小数
export const zeroToHundredTwoPoints =
  /^\d\.([1-9]{1,2}|[0-9][1-9])$|^[1-9]\d{0,1}(\.\d{1,2}){0,1}$|^100(\.0{1,2}){0,1}$|^[0]{1}$/;
// 大于等于0，小于等于100，最多一位小数
export const zeroToHundredOnePoint =
  /^\d\.([1-9]{1})$|^[1-9]\d{0,1}(\.\d{1}){0,1}$|^100(\.0{1}){0,1}$|^[0]{1}$/;
// 0~999的整数
export const zeroToTripleNine = /^[1-9]\d{0,2}$|^[0]{1}$/;
// 最多3位整数，两位小数
export const threeBitPointTwo =
  /^[1-9]\d{0,2}(\.\d{1,2})?$|^0$|^0(\.\d{1,2})?$/;
// 最多8位整数，两位小数
export const eightBitPointTwo = /^(?!0)\d{1,8}(\.\d{1,2})?$|^0$|^0\.\d{1,2}$/;
// 最多10位整数，两位小数
export const tenBitPointTwo = /^(?!0)\d{1,10}(\.\d{1,2})?$|^0$|^0\.\d{1,2}$/;
// 最多九位整数
export const nineBitNum = /^[1-9][0-9]{0,8}$|^[0]{1}$/;
// 邮箱
export const checkMail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
/**
 * 格式化日期格式
 */
const formatDate = (date, type = 'YYYY-MM-DD') => {
  if (!date || date === 'Invalid date') return undefined;
  return moment(date).format(type);
};

/**
 * 日期格式秒转化为时分秒
 */
const formatSeconds = (value) => {
  const result = parseInt(value, 10);
  const h =
    Math.floor(result / 3600) < 10
      ? `0${Math.floor(result / 3600)}`
      : Math.floor(result / 3600);
  const m =
    Math.floor((result / 60) % 60) < 10
      ? `0${Math.floor((result / 60) % 60)}`
      : Math.floor((result / 60) % 60);
  const s =
    Math.floor(result % 60) < 10
      ? `0${Math.floor(result % 60)}`
      : Math.floor(result % 60);

  let res = '';
  if (h !== '00') res += `${h}时`;
  if (m !== '00') res += `${m}分`;
  res += `${s}秒`;
  return res;
};

// IT报事oa加密
const oaEncryption = (oa = '') => {
  const fun = (text, key) => {
    if (!key) {
      return '';
    }
    const keyHex = window.CryptoJS.enc.Utf8.parse(key);
    const encrypted = window.CryptoJS.DES.encrypt(text, keyHex, {
      mode: window.CryptoJS.mode.ECB,
      padding: window.CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  };
  return encodeURIComponent(fun(oa, '12345678'));
};

// 秘钥，必须由16位字符组成
const secretKey = 'Carez2022Paishan';
const AESUtil = {
  /**
   * AES加密方法
   * @param content 要加密的字符串
   * @returns {string} 加密结果
   */
  aesEncrypt: (content) => {
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const srcs = CryptoJS.enc.Utf8.parse(content);
    const encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  },

  /**
   * 解密方法
   * @param encryptStr 密文
   * @returns {string} 明文
   */
  aesDecrypt: (encryptStr) => {
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const decrypt = CryptoJS.AES.decrypt(encryptStr, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypt.toString(CryptoJS.enc.Utf8);
    // return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  },
};

export {
  ok,
  isArray,
  actionType,
  downloadWithUrl,
  previewFileWithUrl,
  strKeyTransfer,
  tidyAjaxData,
  uniqBy,
  pullAllBy,
  checkIDCard,
  isEqualArr,
  setToken,
  getToken,
  removeToken,
  formatDate,
  formatSeconds,
  oaEncryption,
  AESUtil,
  checkLogin,
};
