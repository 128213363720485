/**
 * 套餐管理 页面 api 请求
 */
import carezHttp from '@/utils/request';

/**
* 分页查询套餐列表
* @param {*} params
* @returns
*/
const getPackageList = (data) => {
  return carezHttp({
    url: `manager/package/listPackages`,
    method: 'POST',
    data: data,
  });
 };

/**
* 添加套餐
* @param {*} params
* @returns
*/
const addPackage = (data) => {
 return carezHttp({
   url: `manager/package/addPackage`,
   method: 'POST',
   data: data,
 });
};

/**
* 删除套餐
* @param {*} params
* @returns
*/
const delPackage = (id) => {
 return carezHttp({
   url: `manager/package/deletePackage/${id}`,
   method: 'POST',
 });
};

/**
* 编辑套餐
* @param {*} params
* @returns
*/
const editPackage = (data) => {
 return carezHttp({
   url: `manager/package/updatePackage`,
   method: 'POST',
   data: data,
 });
};

/**
 * 根据套餐id获取商品列表
 * @param {*} data 
 * @returns 
 */
const getGoodsList = (id) => {
  return carezHttp({
    url: `manager/package/listPackageProducts/${id}`,
    method: 'GET',
  });
 };

/**
 * 添加套餐商品
 * @param {*} data 
 * @returns 
 */
const addPackageProduct = (data) => {
  return carezHttp({
    url: `manager/package/addPackageProduct`,
    method: 'POST',
    data: data,
  });
 };

 /**
 * 删除套餐商品
 * @param {*} data 
 * @returns 
 */
const deletePackageProduct = (id) => {
  return carezHttp({
    url: `manager/package/deletePackageProduct/${id}`,
    method: 'POST',
  });
 };

 /**
 * 编辑套餐商品
 * @param {*} data 
 * @returns 
 */
const editPackageProduct = (data) => {
  return carezHttp({
    url: `manager/package/updatePackageProduct`,
    method: 'POST',
    data: data,
  });
 };

/**
 * 获取绑定的商品列表
 * @param {*} data 
 * @returns 
 */
const getBindingList = (id) => {
  return carezHttp({
    url: `manager/package/listProductGoods/${id}`,
    method: 'GET',
  });
 };

/**
 * 绑定商品
 * @param {*} data 
 * @returns 
 */
const bindingGood = (data) => {
  return carezHttp({
    url: `manager/package/bindProductGoods`,
    method: 'POST',
    data: data,
  });
};

/**
 * 删除绑定商品
 * @param {*} data 
 * @returns 
 */
const deleteBinding = (id) => {
  return carezHttp({
    url: `manager/package/deleteProductGoods/${id}`,
    method: 'POST',
  });
};

export {
  getPackageList,
  addPackage,
  delPackage,
  editPackage,
  getGoodsList,
  addPackageProduct,
  deletePackageProduct,
  editPackageProduct,
  getBindingList,
  bindingGood,
  deleteBinding,
}
